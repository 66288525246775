<template>
  <div class="pcontent">
    <div class="pform" style="
          background-color: #ffffff;
          box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
          border-radius: 8px;
        ">
      <el-page-header style="
            width: 100%;
            height: 70px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
          " @back="back" content="创建云课堂">
      </el-page-header>
      <div style="width: 100%">
        <div class="pform--content">
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>课堂名称：
            </div>
            <el-input class="pform--content--row--value" clearable v-model="form.name" placeholder="请输入课堂名称"></el-input>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>发起部门：
            </div>
            <el-cascader class="pform--content--row--value" :show-all-levels="true" v-model="form.areaDepartMentArray"
              :options="areaDepetList" :props="{ label: 'name', value: 'cityCode' }" @change="handleChange"
              ref="deptList"></el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>开始时间：
            </div>
            <el-date-picker class="pform--content--row--value" v-model="form.startTime" value-format="timestamp"
              format="yyyy-MM-dd HH:mm" type="datetime" placeholder="选择开始时间">
            </el-date-picker>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>结束时间：
            </div>
            <el-date-picker class="pform--content--row--value" v-model="form.endTime" value-format="timestamp"
              format="yyyy-MM-dd HH:mm" type="datetime" placeholder="选择结束时间">
            </el-date-picker>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">单位名称：</div>
            <el-input class="pform--content--row--value" clearable v-model="form.companyName"
              placeholder="请输入发起部门"></el-input>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>课堂分类：
            </div>
            <el-cascader class="pform--content--row--value" :show-all-levels="true" v-model="form.courseTypeList"
              :options="courseTypeList" :props="{
                label: 'name',
                value: 'id',
                children: 'childrenList',
                multiple: true,
              }" @change="handleCateChange" ref="cateList"></el-cascader>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>课堂详情：
            </div>
            <el-input class="pform--content--crow--value" type="textarea" :rows="6" placeholder="请输入内容"
              v-model="form.desc" maxlength="2000" show-word-limit>
            </el-input>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>选择课程封面：
            </div>
            <el-upload ref="upload" action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="false" :limit="1" accept=".jpg,.png,.jpeg"
              :on-success="chargedMaterialOnSuccess" :on-remove="chargedMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg" style="align-items: center; justify-content: center">
            <el-button style="width: 50%; margin-top: 30px; margin-bottom: 50px" type="primary" native-type="button"
              @click="submitForm()">提交</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="云课堂" :visible.sync="dialogVisible" @close="back" :destroy-on-close="true">
      <img src="/images/icon_submit_result.png" alt="" style="height: 80px; margin-top: 10px" />
      <div class="result">恭喜您</div>
      <div class="result--des">发布成功，等待管理员审核</div>
      <div class="from_content">
        <div class="from_item">
          <div class="from_item_title">审核单位：</div>
          <div class="from_item_subtitle">
            {{ detail.checkDeptName ? detail.checkDeptName : "" }}
          </div>
        </div>
        <div class="from_item">
          <div class="from_item_title">发起人：</div>
          <div class="from_item_subtitle">{{ detail.nickName }}</div>
        </div>
        <div class="from_item">
          <div class="from_item_title">发起部门：</div>
          <div class="from_item_subtitle">
            {{
              (detail.departmentAreaName ? detail.departmentAreaName : "") +
              (detail.departmentName ? detail.departmentName : "")
            }}
          </div>
        </div>
        <div class="from_item">
          <div class="from_item_title">课程名称：</div>
          <div class="from_item_subtitle">{{ detail.name }}</div>
        </div>
        <div class="from_item">
          <div class="from_item_title">课程时间：</div>
          <div class="from_item_subtitle">{{ detail.timeDesc }}</div>
        </div>
        <div class="from_item">
          <div class="from_item_title">课程类型：</div>
          <div class="from_item_subtitle">{{ courseType }}</div>
        </div>
        <div class="from_item_title">课程介绍：</div>
        <div class="from_item_title" style="margin: 33rpx 50rpx 0rpx 58rpx; color: #333333">
          {{ detail.desc }}
        </div>
      </div>
      <el-button style="width: 50%; margin-bottom: 20px; color: white" class="button_content" native-type="button"
        @click="dialogVisible = false">返回</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "../../utils";
export default {
  name: "details",
  data() {
    return {
      detail: {},
      dialogVisible: false,
      areaDepetList: [],
      superAreas: [],
      depetments: [],
      courseTypeList: [],
      labels: [],
      endDate: new Date(),
      form: {
        name: "",
        startTime: "",
        endTime: "",
        areaDepartMentArray: [],
        courseTypeList: [],
        departmentName: "",
        departmentId: "",
        cityName: "",
        cityCode: "",
        desc: "",
        coverUrl: null,
        isPlayBack: false,
        departmentCityCode: "",
        departmentAreaName: "",
        companyName: "",
      },
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
    courseType() {
      if (this.detail.courseTypeList) {
        return this.detail.courseTypeList.flatMap((item) => item.name).join("、");
      } else {
        return "";
      }
    },
    emptyObj() {
      return {
        name: "",
        startTime: "",
        endTime: "",
        areaDepartMentArray: [],
        departmentName: "",
        departmentId: "",
        cityName: "",
        cityCode: "",
        desc: "",
        coverUrl: null,
        isPlayBack: false,
        courseTypeList: [],
        departmentCityCode: "",
        departmentAreaName: "",
        companyName: "",
      };
    },
  },
  created() {
    this.getCourseCateAction();
    this.getDepartmentAction();
  },
  methods: {
    back() {
      this.dialogVisible = false;
      this.$router.back();
    },
    changeStartDate(value) {
      console.log(value);
    },
    handleChange(value) {
      console.log(value);
      const checkedNodes = this.$refs["deptList"].getCheckedNodes();
      // 打印当前点击省市区label数组
      if (checkedNodes && checkedNodes.length != 0) {
        this.labels = checkedNodes[0].pathLabels;
        // this.form.departmentName = labels.slice(labels.length - 2).join("");
        // console.log(this.form.departmentName);
      }
    },
    handleCateChange(value) {
      console.log(value);
    },
    chargedMaterialOnSuccess(response, file, fileList) {
      this.form.coverUrl = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join("");
      console.log(this.form.coverUrl);
    },
    chargedMaterialOnRemove(file, fileList) {
      this.form.coverUrl = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join("");
      console.log(this.form.coverUrl);
    },
    submitForm() {
      if (isEmpty(this.form.name)) {
        this.$elementMessage({
          showClose: false,
          message: "输入云课堂名称",
          type: "error",
        });
        return;
      }
      if (this.form.areaDepartMentArray.length == 0) {
        this.$elementMessage({
          showClose: false,
          message: "选择发起部门",
          type: "error",
        });
        return;
      }
      if (this.form.startTime == 0) {
        this.$elementMessage({
          showClose: false,
          message: "选择开始时间",
          type: "error",
        });
        return;
      }
      if (this.form.endTime == 0) {
        this.$elementMessage({
          showClose: false,
          message: "选择结束时间",
          type: "error",
        });
        return;
      }
      if (this.form.courseTypeList.length == 0) {
        this.$elementMessage({
          showClose: false,
          message: "选择课程分类",
          type: "error",
        });
        return;
      }
      if (isEmpty(this.form.coverUrl)) {
        this.$elementMessage({
          showClose: false,
          message: "选择课程封面",
          type: "error",
        });
        return;
      }

      let dataDic = { ...this.form };

      //先将数组拉平
      let flatList = this.courseTypeList.flatMap((x) => {
        return x.childrenList;
      });

      let list = [];
      let flatSelectList = this.form.courseTypeList.flat();
      flatList.forEach((item) => {
        if (flatSelectList.includes(item.id)) {
          list.push(item);
        }
      });

      dataDic.courseTypeList = list;
      dataDic.departmentId = this.form.areaDepartMentArray[
        this.form.areaDepartMentArray.length - 1
      ];
      dataDic.departmentCityCode = this.form.areaDepartMentArray[
        this.form.areaDepartMentArray.length - 2
      ];
      dataDic.departmentName = this.labels[this.labels.length - 1];
      dataDic.departmentAreaName = this.labels[this.labels.length - 2];
      if (dataDic.departmentAreaName == "省级") {
        dataDic.departmentAreaName = this.labels[this.labels.length - 3];
      }
      if (dataDic.departmentAreaName == "市级") {
        dataDic.departmentAreaName = this.labels[this.labels.length - 3];
      }
      console.log(dataDic);
      console.log(this.labels);
      const loading = this.$loading.service({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/classroom/saveCloudClassRoomInfo",
          data: dataDic,
          method: "post",
        })
        .then((res) => {
          loading.close();
          if (res.code == 0) {
            that.dialogVisible = true;
            that.$refs.upload.clearFiles();
            that.detail = res.data;
            that.form = that.emptyObj;
          } else {
            that.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.close();
          that.$elementMessage({
            showClose: false,
            message: err,
            type: "error",
          });
        });
    },
    // 获取课程分类
    getCourseCateAction() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/course/findCourseTypeListV2All",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.courseTypeList = res.data;
            this.courseTypeList.forEach((item) => {
              if (item.childrenList) {
                item.childrenList.forEach((subItem) => {
                  subItem.childrenList = undefined;
                });
              }
            });
          }
        })
        .catch((e) => { });
    },
    getDepartmentAction() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/department/getClassroomDepartmentList",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.depetments = res.data;
            this.depetments.forEach((item, index) => {
              item.cityCode = item.id;
            });
            this.getAreaAction();
          }
        })
        .catch((e) => { });
    },
    getAreaAction() {
      let that = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getAreaOpenStatusDataList",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.code == 0 && res.data && res.data.length != 0) {
            let provices = res.data;
            provices.forEach((item, index) => {
              let citys = item.children ? item.children : [];
              citys.forEach((city, index) => {
                let areas = city.children ? city.children : [];
                areas.forEach((area, index) => {
                  area.children = this.depetments;
                });
                let area = {
                  name: city.name.slice(city.name.length - 1) + "级",
                  cityCode: city.cityCode,
                  children: this.depetments,
                };
                areas.unshift(area);
                city.children = areas;
              });

              let city = {
                name: item.name.slice(item.name.length - 1) + "级",
                cityCode: item.cityCode,
                children: this.depetments,
              };
              citys.unshift(city);
              item.children = citys;
            });
            that.areaDepetList = provices;
          }
        })
        .catch((e) => { });
    },
  },
};
</script>

<style scoped lang="scss">
.from_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  margin-bottom: 80px;
  margin-top: 50px;
}

.button_content {
  width: 70%;
  height: 50px;
  background: linear-gradient(90deg, #0090d7, #51d2be);
  border-radius: 47px;
}

.from_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}

.from_item_title {
  color: #626666;
  font-size: 19px;
  margin-left: 20px;
  flex: 1;
  text-align: left;
}

.from_item_subtitle {
  color: #333333;
  font-size: 19px;
  font-weight: 500;
  margin-right: 20px;
  text-align: right;
}

.pcontent {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result {
  color: #0090d7;
  font-size: 30px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;

  &--des {
    font-size: 19px;
    font-weight: 500;
  }
}

.pform {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &--content {
    width: 100%;
    position: relative;

    &--row_lg {
      width: 100% !important;
      display: flex;
      flex-direction: row;
      align-items: flex-start !important;
    }

    &--row {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 0 16px;
      box-sizing: border-box;
      margin-top: 16px;
      min-height: 50px;
      float: left;

      &--label {
        color: #000000;
        font-size: 16px;
        text-align: right;
      }

      &--value {
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .pform {
    width: 100%;
  }

  .pform--content--row {
    width: 100%;
  }

  .pform--content--row--label {
    width: 80px;
  }
}

@media screen and (min-width: 800px) {
  .pform {
    width: 70%;
  }

  .pform--content--row {
    width: 50%;
  }

  .pform--content--row--label {
    width: 170px;
  }
}
</style>
